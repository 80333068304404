import React, { FC, ReactNode } from 'react'
import { ThemeProvider } from '@babylon/medkit'
import { useSelector } from 'react-redux'

import themes from './themes'

const useTheme = () => {
  const themeName = useSelector<any, any>((state) => state.config.theme)

  return themes[themeName]
}

interface ThemeProps {
  children?: ReactNode
  isRoot?: boolean
}

const Theme: FC<ThemeProps> = ({ children, isRoot = false }: ThemeProps) => {
  const theme = useTheme()

  if (!theme) {
    return <>{children}</>
  }

  return (
    <ThemeProvider theme={theme} root={isRoot}>
      {children}
    </ThemeProvider>
  )
}

export default Theme
export { useTheme }
