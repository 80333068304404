import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { PrivacyNoticeFragment } from '@/components/Privacy/fragments'
import Loading from '@/components/Loading'
import LandingHeader from '@/components/LandingHeader'
import LandingFooter from '@/components/Footer/LandingFooter'
import appStyles from '@/App.module.scss'
import './NoticePage.scss'

enum NoticeType {
  privacy = 'privacyPolicy',
  terms = 'termsAndConditions',
}

interface Props {
  type: NoticeType
}

const PrivacyPolicyQuery = gql`
  query privacyPolicy {
    privacyPolicy {
      ...privacy_notice
    }
  }
  ${PrivacyNoticeFragment}
`

const TermsAndConditionsQuery = gql`
  query termsAndConditions {
    termsAndConditions {
      ...privacy_notice
    }
  }
  ${PrivacyNoticeFragment}
`

const getQueryForType = (type: NoticeType) => {
  switch (type) {
    case NoticeType.privacy:
      return PrivacyPolicyQuery
    case NoticeType.terms:
      return TermsAndConditionsQuery
    default:
      return TermsAndConditionsQuery
  }
}

const NoticePage: React.FC<Props> = ({ type }) => {
  const { loading, data } = useQuery(getQueryForType(type))

  if (loading) {
    return <Loading heart />
  }

  const notice = data?.[type]

  return (
    <>
      <LandingHeader />
      <main className={appStyles.view}>
        <section className={appStyles.view__inner}>
          <div
            id="noticePage"
            dangerouslySetInnerHTML={{ __html: notice.longText }}
          />
        </section>
      </main>
      <LandingFooter />
    </>
  )
}

export default NoticePage
