import {
  TRACKING_PREFERENCE,
  TRACKING_SCRIPTS_LOADED,
  MARKETING_TAGS_ADDED,
} from './actionTypes'
import { storeTrackingPreference } from './persistence'
import { initNonEssentialTrackers } from './marketing-tags/trackers'
import { MarketingTag } from './marketing-tags/types'

const marketingTagsAdded = (tags: MarketingTag[]) => ({
  type: MARKETING_TAGS_ADDED,
  tags,
})

const trackingPreference = (accepted: boolean) => ({
  type: TRACKING_PREFERENCE,
  accepted,
})

const trackingPreferenceDecision = (accepted: boolean) => (dispatch: any) => {
  if (accepted) {
    initNonEssentialTrackers()
  }

  storeTrackingPreference(accepted)

  return dispatch(trackingPreference(accepted))
}

const trackingScriptsLoaded = () => ({
  type: TRACKING_SCRIPTS_LOADED,
})

export { trackingPreferenceDecision, trackingScriptsLoaded, marketingTagsAdded }
