/**
 * Measure custom events using Facebook Pixel
 *
 * https://developers.facebook.com/docs/facebook-pixel/
 *
 */
import {
  standardEvents,
  standardProps,
  EventName,
  StandardEventProps,
} from './fbq'

/**
 * parameters need to contain event name to be tracked
 */
const nonStandardEventName = (event: EventName): boolean =>
  !standardEvents.includes(event)

/**
 * based on event name, filter out non-standard object properties
 * see Facebook Pixel Reference docs for allowed props of Standard Events
 */
const getAllowedProps = (event: EventName, props: any): StandardEventProps => {
  const allowedProps = Object.keys(standardProps[event])
  const isAllowedProp = (prop: string) => allowedProps.includes(prop)

  allowedProps.forEach((prop) => {
    const isRequired = standardProps[event][prop]
    const isProvided = props[prop]

    if (isRequired && !isProvided) {
      throw new Error(
        `Facebook Pixel event '${event}' requires '${prop}' to be provided`
      )
    }
  })

  return Object.keys(props)
    .filter(isAllowedProp)
    .reduce(
      (filteredProps, prop) => ({ ...filteredProps, [prop]: props[prop] }),
      {}
    )
}

export type EventTracker = (
  event: EventName,
  props?: StandardEventProps
) => void

export default (fbq: facebook.Pixel.Event): EventTracker => (
  event,
  props = {}
) => {
  if (nonStandardEventName(event)) {
    throw new Error(`Non-standard Facebook Pixel event: ${event}`)
  }

  fbq('track', event, getAllowedProps(event, props))
}
