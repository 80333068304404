import initGoogleAds from '../google/trackers'
import initBingAds from '../bing/trackers'
import initFacebookAds from '../facebook/trackers'
import initAwinTrackers from '../awin/trackers'
import { FACEBOOK_PIXEL_TOKEN, GOOGLE_ADS_TRACKING_ID } from '@/config'
import { reduxState } from '@/redux'
import { trackingScriptsLoaded } from '../../actions'
import { Tracker } from '../types'

const initNonEssentialTrackers = async () => {
  const { store } = reduxState

  initGoogleAds(GOOGLE_ADS_TRACKING_ID)
  initBingAds()
  initAwinTrackers(store)
  await initFacebookAds(FACEBOOK_PIXEL_TOKEN)

  const { tracking } = store.getState()
  if (tracking.marketingTags.length) {
    for (const tag of tracking.marketingTags) {
      if (tag.type === Tracker.Facebook) {
        await initFacebookAds(tag.id)
      }

      if (tag.type === Tracker.Google) {
        initGoogleAds(tag.id)
      }
    }
  }

  store.dispatch(trackingScriptsLoaded())
}

export { initNonEssentialTrackers }
