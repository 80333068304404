import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { getHeaders } from '@babylon/shared-utils'
import { getAuthToken } from '@babylon/auth0'
import { VERSION } from './config'

import { parseError } from './utils'
import feedbackMessages from './utils/messages'
import { reduxState } from './redux'
import { feedbacks } from './redux/flash/constants'
import { updateApolloRequestCount } from './redux/actions'
import { displayFlashError, displayFlashMessage } from './redux/flash/actions'
import { displayFormErrors } from './redux/formErrors/actions'
import { logout } from './components/Authentication/actions'
import debugLog from '@/utils/debugLog'

const errorParsingAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (response.errors && response.errors.length) {
      const parsedError = parseError(response.errors[0])
      const errorData = parsedError.response && parsedError.response.data

      if (errorData) {
        return {
          ...response,
          errors: [...response.errors, { parsedError: errorData }],
        }
      }

      return response
    }

    return response
  })
)

const errorLoggingAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (response.errors && response.errors.length) {
      debugLog(response.errors, 'warn')
    }

    return response
  })
)

const isFormError = ({ data, status }) =>
  data && ![401, 403, 404].includes(status) && status < 500

const formFeedbackAfterware = onError(
  ({ graphQLErrors, operation, forward }) => {
    const { store } = reduxState

    if (graphQLErrors && graphQLErrors.length) {
      const context = operation.getContext()

      if (context.skipFormError) {
        return forward(operation)
      }

      const error = graphQLErrors[0]
      const errorObj = parseError(error)

      if (errorObj.response && isFormError(errorObj.response)) {
        // letting the flash error middleware know that
        // the error is already displayed in the form
        operation.setContext({
          errorDisplayedOnForm: true,
        })
        store.dispatch(
          displayFormErrors(operation.operationName, errorObj.response.data)
        )

        return forward(operation)
      }

      if (errorObj.unparsedError) {
        // A non-JSON unknown error
        store.dispatch(displayFlashError(feedbackMessages.negativeFeedback))
      }
    }

    return undefined
  }
)

/* TO DO: [Refactor] [CW-1342] Reduce complexity to align with new linting rules */
/* eslint-disable complexity */
const flashErrorAfterware = onError(
  ({ networkError, operation, graphQLErrors, forward }) => {
    const context = operation.getContext()
    const { store } = reduxState

    // skip notification errors since we poll every 10 seconds
    if (
      (operation.operationName || '').toLowerCase().includes('notification') ||
      (context.showFeedback && context.showFeedback.failure === false)
    ) {
      return forward(operation)
    }

    if (networkError) {
      if (context.showFeedback && context.showFeedback.failure) {
        const msg = context.showFeedback.failure

        if (typeof msg === 'function') {
          return store.dispatch(displayFlashError(msg(networkError)))
        }

        if (typeof msg === 'string') {
          store.dispatch(displayFlashError(msg))

          return forward(operation)
        }

        store.dispatch(displayFlashError(feedbackMessages.negativeFeedback))

        return forward(operation)
      }

      store.dispatch(displayFlashError(feedbackMessages.negativeFeedback))

      return forward(operation)
    }

    if (graphQLErrors && graphQLErrors.length) {
      const error = graphQLErrors[0]
      const errorObj = parseError(error)
      let msg = feedbackMessages.negativeFeedback

      // check if we have added a custom message to display
      if (
        context.showFeedback &&
        context.showFeedback.failure &&
        context.showFeedback.failure !== true
      ) {
        const { failure: failMessage } = context.showFeedback
        msg =
          typeof failMessage === 'function'
            ? failMessage(errorObj)
            : failMessage
      }

      if (
        errorObj.response &&
        errorObj.response.data &&
        errorObj.response.status >= 403 &&
        // check if error is already displayed on the form
        !context.errorDisplayedOnForm
      ) {
        store.dispatch(displayFlashError(msg))

        return forward(operation)
      }
    }

    return undefined
  }
)
/* eslint-enable */

const AUTH_ERROR_STATUS_CODES = [401]
const isAuthErrorResponse = ({ status }) =>
  AUTH_ERROR_STATUS_CODES.includes(status)
const isLoginOperation = ({ operationName }) =>
  operationName.toLowerCase().includes('login')

const unauthorizedRequestAfterware = onError(
  ({ operation, graphQLErrors, forward }) => {
    if (!graphQLErrors || (graphQLErrors && graphQLErrors.length < 1)) {
      return forward(operation)
    }

    const error = graphQLErrors[0]
    const errorObj = parseError(error)
    const { store } = reduxState

    if (
      errorObj.response &&
      !isLoginOperation(operation) &&
      isAuthErrorResponse(errorObj.response)
    ) {
      store.dispatch(
        logout({
          message: feedbackMessages.unauthorized,
        })
      )

      return forward(operation)
    }

    return forward(operation)
  }
)

const flashFeedbackAfterware = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  const { store } = reduxState

  /* TO DO: [Refactor] [CW-1343] Reduce complexity to align with new linting rules */
  /* eslint-disable complexity */
  return forward(operation).map((response) => {
    store.dispatch(updateApolloRequestCount())

    if (context.showFeedback && !(response.errors && response.errors.length)) {
      const feedback = context.showFeedback.type || feedbacks.positive

      if (context.showFeedback.success) {
        const msg = context.showFeedback.success

        if (typeof msg === 'function') {
          store.dispatch(displayFlashMessage(msg(response), feedback))
        } else if (typeof msg === 'string' || typeof msg === 'object') {
          store.dispatch(displayFlashMessage(msg, feedback))
        } else {
          store.dispatch(
            displayFlashMessage(feedbackMessages.positiveFeedback, feedback)
          )
        }

        return response
      }
    }

    return response
  })
  /* eslint-enable */
})

// TODO: https://babylonpartners.atlassian.net/browse/CW-2214
// R-E-S-P-E-C-T the users preferred locales in the browsers default Accept-Language header
const setRequestHeaders = (appName, acceptLanguage) =>
  setContext(async () => {
    let token

    try {
      token = await getAuthToken()
    } catch {
      // when we are not logged in, eg for `/get-started/nhs/eligibility`
      // catch and set token to null so we can proceed
      token = null
    }

    const authHeader = token ? { authorization: `Bearer ${token}` } : null

    return {
      headers: {
        ...getHeaders({
          acceptLanguage,
          appId: appName,
          appVersion: VERSION,
        }),
        ...authHeader,
      },
    }
  })

const setWebViewRequestHeaders = setContext((_, { headers }) => {
  // get the authentication token from window if it exists
  // in a mobile webview this value will be injected into
  // graphql requests for webview flows

  const token = window.webViewAuthToken

  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  }
})

export {
  errorParsingAfterware,
  errorLoggingAfterware,
  formFeedbackAfterware,
  flashErrorAfterware,
  unauthorizedRequestAfterware,
  flashFeedbackAfterware,
  setRequestHeaders,
  setWebViewRequestHeaders,
}
