import { combineReducers } from 'redux'

import register from './Register/reducer'
import { getLastActiveTime, storeLastActiveTime } from './utils'
import {
  LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IDLE,
  UPDATE_ACTIVE_TIME,
} from './actionTypes'

const initialState = {
  id: null,
  uuid: null,
  isIdle: false,
  isLoading: true,
  lastActiveTime: getLastActiveTime(),
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      storeLastActiveTime({ lastActiveTime: action.lastActiveTime })

      return {
        ...state,
        id: action.patient.id,
        uuid: action.patient.uuid,
        isIdle: false,
        lastActiveTime: action.lastActiveTime,
      }
    case LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case UPDATE_ACTIVE_TIME:
      return {
        ...state,
        lastActiveTime: action.lastActiveTime,
        isIdle: false,
      }
    case SET_IDLE:
      return {
        ...state,
        isIdle: action.isIdle,
      }
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isIdle: state.isIdle,
        lastActiveTime: getLastActiveTime(),
      }
    default:
      return state
  }
}

export default combineReducers({
  user,
  register,
})
