import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import generalMessages from '@/App.messages'
import { useLogo } from '@/redux/selectors'
import styles from './LandingHeader.module.scss'
import Logo from '../Logo'

const LandingHeader = () => {
  const logo = useLogo()
  const translate = useFormatMessage()

  return (
    <header className={styles.loginHeader}>
      <a
        className={styles.logo}
        title={translate(generalMessages.ctaHome)}
        href="/"
      >
        <Logo name={logo} className={styles[`${logo}-logo`]} />
      </a>
    </header>
  )
}

export default LandingHeader
