import React, { forwardRef } from 'react'
import classNames from 'classnames/bind'

import { Link, Button, Text, ButtonVariant } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'

import { useCookiePolicyLink } from '@/redux/selectors'
import messages from './messages'
import styles from './Banner.module.scss'

const cx = classNames.bind(styles)

interface Props {
  isHidden: boolean
  confirm: () => void
  decline: () => void
}

export type Ref = HTMLDivElement

const Banner = forwardRef<Ref, Props>(
  ({ isHidden, confirm, decline }: Props, ref) => {
    const cookiePolicyLink = useCookiePolicyLink()
    const translate = useFormatMessage()

    return (
      <div
        data-testid="cookieNotice"
        role="alert"
        className={cx(styles.cookieNotice, {
          'cookieNotice--hidden': isHidden,
        })}
        ref={ref}
      >
        <div className={styles.cookieNotice__inner}>
          <div className={styles.cookieNotice__content}>
            <Text variant="body">
              {translate(messages.info, {
                a: (...chunks: any) => (
                  <Link key="cookiePolicyLink" blank href={cookiePolicyLink}>
                    {chunks}
                  </Link>
                ),
              })}
            </Text>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              onClick={confirm}
              data-testid="acceptCookies"
            >
              {translate(messages.confirmText)}
            </Button>
            <Button
              className={cx(styles.button, styles.declineButton)}
              onClick={decline}
              variant={ButtonVariant.secondary}
              data-testid="declineCookies"
            >
              {translate(messages.declineText)}
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

export default Banner
