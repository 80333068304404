import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-common'
import { Provider, ReactReduxContext } from 'react-redux'

import { initSentry } from '@babylon/sentry'
import { ConnectedRouter } from 'connected-react-router'
import { loadableReady } from '@loadable/component'

import App from './App'
import Theme from './theme'
import ErrorBoundary from './components/ErrorBoundary'
import ErrorPage from './views/ErrorPage'
import { init as initApolloClient, getClient } from './config/apollo-client'
import { reduxState, initStore } from './redux'
import config from './config'
import { IntlProvider } from './localisation'
import AuthProvider from './AuthProvider'

import '@babylon/fonts/Visuelt'
import { selectDefaultLanguage } from './redux/selectors'

if (config.SENTRY_URL) {
  initSentry({
    dsn: config.SENTRY_URL,
    environment: config.SENTRY_ENVIRONMENT,
    release: config.VERSION,
  })
}

if (!config.TRACKING_DISABLED) {
  import('./tracking').then((tracking) => {
    tracking.init(reduxState.store)
  })
}

if (config.MARKETING_TAGS_ENABLED) {
  import('./tracking/marketing-tags').then((tracking) => {
    tracking.init({ store: reduxState.store })
  })
}

const getDefaultLanguage = () =>
  selectDefaultLanguage(reduxState.store.getState())

initStore()
initApolloClient({ store: reduxState.store })

const client = getClient()

const Root = () => (
  <Provider store={reduxState.store} context={ReactReduxContext}>
    <ApolloProvider client={client}>
      <IntlProvider defaultLocale={getDefaultLanguage()}>
        <Theme isRoot>
          <AuthProvider>
            <ErrorBoundary Component={ErrorPage}>
              <ConnectedRouter
                history={reduxState.history}
                context={ReactReduxContext}
              >
                <App />
              </ConnectedRouter>
            </ErrorBoundary>
          </AuthProvider>
        </Theme>
      </IntlProvider>
    </ApolloProvider>
  </Provider>
)

if (module.hot) {
  ReactDOM.render(<Root />, document.getElementById('root'))
} else {
  loadableReady(() => {
    ReactDOM.hydrate(<Root />, document.getElementById('root'))
  })
}
