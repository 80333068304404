import gql from 'graphql-tag'

export default gql`
  fragment patient on Patient {
    id
    uuid
    avatar_image_path
    avatar_full_url
    date_of_birth
    minor
    email
    first_name
    last_name
    gender
    smoking_status
    weight
    height
    address_first_line
    address_post_code
    address_second_line
    address_third_line
    insurance_company_id
    insurance_membership_number
    is_password_present
    idVerification {
      status
      isInvalid
    }
    account_state
    queued_for_activation
    consumer_networks {
      id
      uuid
      name
    }
    promotion {
      id
      code
      code_prefix
      credit_card_required_text
      description
      duration
      expires_on
      is_credit_card_required
      logo
      name
      percent_off
      promotion_type
    }
    paymentPlans {
      gp_appointment_price
      id
      price
      title
      subtitle
    }
    phone_country_code
    phone_number
    preferred_consumer_network_id
    preferred_consumer_network_uuid
    subscription {
      credit_card {
        id
      }
      id
      state
      price
      next_billing_date
      plan {
        id
        title
        price
        gp_appointment_price
        specialist_appointment_price
        therapist_appointment_price
        pending
        is_default
      }
    }
    region {
      id
      iso_code
      age_of_consent
      default_phone_country_code
      support_number
      currency {
        symbol
        symbol_first
        decimal_mark
        thousands_separator
      }
    }
    healthcare_identifier {
      public_identifier
    }
  }
`
