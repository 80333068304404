import { defineMessages } from 'react-intl'

export default defineMessages({
  enterPassword: {
    id: 'Modal.enterPassword',
    defaultMessage: 'Enter Password',
  },
  createPassword: {
    id: 'Modal.createPassword',
    defaultMessage: 'Create Password',
  },
  create: {
    id: 'Modal.create',
    defaultMessage: 'Create',
  },
  clinicalSecurity: {
    id: 'Modal.clinicalSecurity',
    defaultMessage:
      'The security of your medical records is our top concern. To continue, please re-enter your password.',
  },
  clinicalSecurityCreatePassword: {
    id: 'Modal.clinicalSecurityCreatePassword',
    defaultMessage:
      'The security of your medical records is our top concern. To continue, please create a password.',
  },
  passwordTip: {
    id: 'Modal.passwordTip',
    defaultMessage:
      'Minimum 8 characters with a number, uppercase & lowercase letter',
  },
  password: {
    id: 'Modal.password',
    defaultMessage: 'Password',
  },
  resetPassword: {
    id: 'Modal.resetPassword',
    defaultMessage: 'Forgotten password?',
  },
  passwordCreated: {
    id: 'Modal.passwordCreated',
    defaultMessage: 'Password created',
  },
  reAuthenticate: {
    id: 'Modal.reAuthenticate',
    defaultMessage: 'Re-Authenticate',
  },
  cancelConsultation: {
    id: 'Modal.cancelConsultation',
    defaultMessage: 'Cancel Consultation',
  },
  whyCancel: {
    id: 'Modal.whyCancel',
    defaultMessage:
      'Please tell us why you would like to cancel your appointment:',
  },
  save: {
    id: 'Modal.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Modal.cancel',
    defaultMessage: 'Cancel',
  },
  add: {
    id: 'Modal.add',
    defaultMessage: 'Add',
  },
  confirm: {
    id: 'Modal.confirm',
    defaultMessage: 'Confirm',
  },
  close: {
    id: 'Modal.close',
    defaultMessage: 'Close this dialog',
  },
  back: {
    id: 'Modal.back',
    defaultMessage: 'Back',
  },
  continuityOfCare: {
    id: 'Modal.continuityOfCare',
    defaultMessage: 'Continuity of Care',
  },
  consultationNotesQuestion: {
    id: 'Modal.consultationNotesQuestion',
    defaultMessage:
      'Would you like to send consultation notes to your regular GP?',
  },
  consultationNotesInfo: {
    id: 'Modal.consultationNotesInfo',
    defaultMessage:
      'Consultation notes can include letters and notifications of change in medication.',
  },
  send: {
    id: 'Modal.send',
    defaultMessage: 'Send',
  },
  confirmCancellation: {
    id: 'Modal.confirmCancellation',
    defaultMessage: 'Confirm cancellation',
  },
  dontSend: {
    id: 'Modal.dontSend',
    defaultMessage: 'Don’t Send',
  },
  gender: {
    id: 'Modal.gender',
    defaultMessage: 'Gender',
  },
  selectGender: {
    id: 'Modal.selectGender',
    defaultMessage: 'Please select your gender',
  },
  male: {
    id: 'Modal.male',
    defaultMessage: 'Male',
  },
  female: {
    id: 'Modal.female',
    defaultMessage: 'Female',
  },
  titleMissing: {
    id: 'Modal.titleMissing',
    defaultMessage: 'Confirm missing information',
  },
  titleMissingGp: {
    id: 'Modal.titleMissingGp',
    defaultMessage: 'GP Details',
  },
  dateOfBirth: {
    id: 'Modal.dateOfBirth',
    defaultMessage: 'Date of Birth',
  },
  day: {
    id: 'Modal.day',
    defaultMessage: 'Day',
  },
  month: {
    id: 'Modal.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'Modal.year',
    defaultMessage: 'Year',
  },
  telephoneNumber: {
    id: 'Modal.telephoneNumber',
    defaultMessage: 'Telephone Number',
  },
  phoneNumberValidation: {
    id: 'Modal.phoneNumberValidation',
    defaultMessage: 'Please provide a valid phone number',
  },
  promotionApplied: {
    id: 'Modal.promotionApplied',
    defaultMessage: 'Promotion applied',
  },
  membershipApplied: {
    id: 'Modal.membershipApplied',
    defaultMessage: 'Membership code applied',
  },
  planCongratulations: {
    id: 'Modal.planCongratulations',
    defaultMessage: 'Congratulations, your plan is active!',
  },
  confirmationEmail: {
    id: 'Modal.confirmationEmail',
    defaultMessage: 'A confirmation email has been sent to you.',
  },
  continue: {
    id: 'Modal.continue',
    defaultMessage: 'Continue',
  },
  noSpecialistsFound: {
    id: 'Modal.noSpecialistsFound',
    defaultMessage: 'No specialists found',
  },
  addFamilyMember: {
    id: 'Modal.addFamilyMember',
    defaultMessage: 'Add Family Member',
  },
  editFamilyMember: {
    id: 'Modal.editFamilyMember',
    defaultMessage: 'Edit Family Member',
  },
  firstName: {
    id: 'Modal.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'Modal.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'Modal.email',
    defaultMessage: 'Email',
  },
  other: {
    id: 'Modal.other',
    defaultMessage: 'Other. Please explain:',
  },
  emailRequired: {
    id: 'Modal.emailRequired',
    defaultMessage: 'Email required over {ageOfConsent} years old.',
  },
  childWarning: {
    id: 'Modal.childWarning',
    defaultMessage:
      'Please remember that for children under the age of {ageOfConsent} all consultations must be via video and a parent or guardian must be present.',
  },
  saveGpDetailsSuccessful: {
    id: 'Modal.saveGpDetailsSuccessful',
    defaultMessage: 'Your GP details have been saved',
  },
  saveFamilySuccessful: {
    id: 'Modal.saveFamilySuccessful',
    defaultMessage: 'Your family member details have been saved',
  },
  saveUnsuccessful: {
    id: 'Modal.saveUnsuccessful',
    defaultMessage: 'Save Unsuccessful, please try again',
  },
  lessInfo: {
    id: 'PrivacyNotice.lessInfo',
    defaultMessage: 'Less Info',
  },
  queueTitle: {
    id: 'QueueModal.title',
    defaultMessage: 'Hold on tight',
  },
  queueDescription: {
    id: 'QueueModal.description',
    defaultMessage:
      'We’re working hard on processing your application to the GP at hand service. We will be with you shortly.',
  },
  queueAppsTitle: {
    id: 'QueueModal.downloadAppsTitle',
    defaultMessage: 'Get the eMed app while you wait.',
  },
  accountLockedHeading: {
    id: 'Modal.clinicalSecurity.accountLocked',
    defaultMessage: 'Account locked',
  },
})
