import { defineMessages } from 'react-intl'

export default defineMessages({
  'gp-at-hand-logo': {
    id: 'App.gp-at-hand-logo',
    defaultMessage: 'GP at Hand - Home',
  },
  'nhs-services-logo': {
    id: 'App.nhs-services-logo',
    defaultMessage: 'NHS: Providing NHS Services',
  },
})
