import { trackView } from '@babylon/tracking/tealium'
import { TealiumView } from '@babylon/tracking/tealium/types'

import debugLog from '@/utils/debugLog'
import getExtraProps from './getExtraProps'
import { TealiumPropsWithoutExtras } from './types'

const trackTealiumView = (params: TealiumPropsWithoutExtras<TealiumView>) => {
  const eventParams: TealiumView = {
    ...params,
    ...getExtraProps(),
  }

  const tealiumData = trackView(eventParams)

  debugLog(
    { eventParams, tealiumData },
    'info',
    `[Web Signup] Track view: ${eventParams.screen_title}`
  )
}

export default trackTealiumView
