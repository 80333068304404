import axios from 'axios'
import {
  RedirectLoginOptions,
  LogoutOptions,
  GetTokenSilentlyOptions,
  User,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js'
import { AuthClient } from './auth-client'
import { CoreAuth2Config } from './auth0'

export class CoreAuth2Client implements AuthClient {
  private config: CoreAuth2Config
  private accessToken?: string
  private accessTokenPromise: Promise<void>
  private userId?: string

  constructor(config: CoreAuth2Config) {
    this.config = config
    this.accessTokenPromise = this.getAccessToken()
  }

  private async getAccessToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(
          this.config.url,
          {
            jwt: this.config.idToken,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-App-Name': this.config.organisation,
            },
          }
        )
        .then((response) => {
          this.accessToken = response.data.session.access_token
          this.userId = response.data.uuid
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getTokenSilently(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _options?: GetTokenSilentlyOptions
  ): Promise<string> {
    await this.accessTokenPromise
    if (!this.accessToken) {
      throw new Error('Access token not found')
    }
    return this.accessToken
  }

  async getUser<TUser extends User>(): Promise<TUser | undefined> {
    await this.accessTokenPromise
    const user = {} as TUser
    ;(user as User)['https://babylonhealth.com/user'] = this.userId
    return Promise.resolve(user)
  }

  // eslint-disable-next-line class-methods-use-this
  async loginWithRedirect<TAppState = any>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _options?: RedirectLoginOptions<TAppState>
  ): Promise<void> {
    throw new Error('Not supported in core-auth-2 client')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logout(_options?: LogoutOptions): void | Promise<void> {
    this.accessToken = undefined
    this.userId = undefined
  }

  // eslint-disable-next-line class-methods-use-this
  async handleRedirectCallback<TAppState = any>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _url?: string
  ): Promise<RedirectLoginResult<TAppState>> {
    throw new Error(
      'Not supported in core-auth-2 client: handleRedirectCallback'
    )
  }
}
