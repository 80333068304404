import {
  RESET_STORE,
  PRIVACY_AGREEMENT_NEEDED,
  UPDATE_APOLLO_REQUEST_COUNT,
} from './constants'

const resetStore = () => ({ type: RESET_STORE })

const setPrivacyAgreementNeeded = (value) => ({
  type: PRIVACY_AGREEMENT_NEEDED,
  value,
})

const updateApolloRequestCount = () => ({
  type: UPDATE_APOLLO_REQUEST_COUNT,
})

export { resetStore, setPrivacyAgreementNeeded, updateApolloRequestCount }
