type logTypes = Pick<Console, 'log' | 'info' | 'warn' | 'error'>

const { BABYLON_ENVIRONMENT } = window?.babylonConfig

const debugLog = (
  args: any,
  type: keyof logTypes = 'log',
  groupName?: string
) => {
  if (BABYLON_ENVIRONMENT === 'prod') {
    return
  }

  const logFn = console[type] || console.log // eslint-disable-line no-console

  if (groupName) {
    // eslint-disable-next-line no-console
    console.group(groupName)
  }

  if (Array.isArray(args)) {
    logFn(...args)
  } else {
    logFn(args)
  }

  if (groupName) {
    // eslint-disable-next-line no-console
    console.groupEnd()
  }
}

export default debugLog
