import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Banner from './Banner'
import { Ref } from './Banner/Banner'
import { trackingPreferenceDecision } from '@/tracking/actions'

const ANIMATE_TIME = 300

const CookieNotice = () => {
  const displayCookieNotice = useSelector<any, boolean>(
    (state) => state.tracking.displayCookieNotice
  )
  const dispatch = useDispatch()
  const [isHidden, setIsHidden] = useState(false)
  const [isOpen, setIsOpen] = useState(displayCookieNotice)
  const bannerRef = useRef<Ref>(null)

  const setTrackingPreference = (accepted: boolean) => {
    dispatch(trackingPreferenceDecision(accepted))

    setIsHidden(true)
    setTimeout(() => setIsOpen(false), ANIMATE_TIME)
  }

  // in order to allow the user to operate the app without dismissing the banner
  // we must add padding to the body to ensure they can scroll to the bottom of
  // the page and access submit buttons at the bottom of forms or the footer
  useEffect(() => {
    if (isOpen && bannerRef.current) {
      // @ts-ignore
      // ignore warning that ref may be null
      const { height } = bannerRef.current.getBoundingClientRect()
      document.body.style.paddingBottom = `${height}px`
    } else {
      document.body.style.paddingBottom = '0px'
    }
  }, [isOpen, bannerRef])

  if (!isOpen) {
    return null
  }

  return (
    <Banner
      isHidden={isHidden}
      confirm={() => setTrackingPreference(true)}
      decline={() => setTrackingPreference(false)}
      ref={bannerRef}
    />
  )
}
export default CookieNotice
