import { logoutTimeout } from '@/config/constants'

const LAST_ACTIVE_KEY = 'web_app_last_active'

const getLastActiveTime = () =>
  localStorage.getItem(LAST_ACTIVE_KEY) || Date.now()

const storeLastActiveTime = ({ lastActiveTime }) => {
  localStorage.setItem(LAST_ACTIVE_KEY, lastActiveTime)
}

const shouldLogout = () => {
  const lastActiveTime = getLastActiveTime()

  return !lastActiveTime || lastActiveTime < Date.now() - logoutTimeout
}

export { getLastActiveTime, shouldLogout, storeLastActiveTime, LAST_ACTIVE_KEY }
