import { trackEvent } from '@babylon/tracking/tealium'
import { TealiumEvent } from '@babylon/tracking/tealium/types'

import debugLog from '@/utils/debugLog'
import getExtraProps from './getExtraProps'

const lowercaseParams = ['eventAction', 'eventCategory', 'eventLabel']

const trackTealiumEvent = (params: TealiumEvent) => {
  const parsedParams = { ...params }

  Object.keys(parsedParams).map((el) => {
    const shouldBeLowercase = lowercaseParams.includes(el)

    if (shouldBeLowercase) {
      parsedParams[el]?.toLowerCase()
    }

    return el
  })

  const eventParams: TealiumEvent = {
    ...parsedParams,
    ...getExtraProps(),
  }

  const tealiumData = trackEvent(eventParams)

  debugLog(
    { eventParams, tealiumData },
    'info',
    `[Web Signup] Track event: ${eventParams.eventAction}`
  )
}

export default trackTealiumEvent
