import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'AppsInfo.title',
    defaultMessage: 'Have you got the app?',
  },
  featureUnavailable: {
    id: 'AppsInfo.featureUnavailable',
    defaultMessage: 'This feature is only available on our apps',
  },
  content: {
    id: 'AppsInfo.content',
    defaultMessage:
      'For the full experience, and access to all of our features, download the app today.',
  },
  iosDownloadAltText: {
    id: 'AppsInfo.iosDownloadAltText',
    defaultMessage: 'Download on the App Store (opens in a new browser tab)',
  },
  playStoreAltText: {
    id: 'AppsInfo.androidDownloadAltText',
    defaultMessage: 'Get it on Google Play (opens in a new browser tab)',
  },
})
