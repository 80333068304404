import createAuth0Client from '@auth0/auth0-spa-js'
import decode from 'jwt-decode'
import { AuthClient } from './auth-client'
import { CoreAuth2Client } from './core-auth-2-client'

export interface Auth0Config {
  clientId: string
  domain: string
}

export interface CoreAuth2Config {
  url: string
  idToken?: string
  organisation?: string // to be moved inside ID token
}

let client: AuthClient | null = null

export const initialise = async (
  config?: Auth0Config,
  coreAuth2Config?: CoreAuth2Config
) => {
  if (coreAuth2Config && coreAuth2Config.idToken) {
    client = await new CoreAuth2Client(coreAuth2Config)
  }

  if (config && !client) {
    client = await createAuth0Client({
      domain: config.domain,
      client_id: config.clientId,
      redirect_uri: `${window.location.origin}/callback`,
      response_type: 'code',
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      advancedOptions: {
        defaultScope: 'openid',
      },
    })
  }
}

export const register = async (queryParams: Record<string, any> = {}) =>
  client?.loginWithRedirect({
    ...queryParams,
    prompt: 'login',
    mode: 'sign-up',
    scope: 'openid',
    appState: { targetUrl: window.location.pathname, registration: true },
  })

export const login = async (
  email?: string,
  queryParams: Record<string, any> = {}
) =>
  client?.loginWithRedirect({
    ...queryParams,
    prompt: 'login',
    scope: 'openid',
    mode: 'sign-in',
    appState: { targetUrl: window.location.pathname },
    ...(email ? { login_hint: email } : {}),
  })

export const logout = () => client?.logout()

export const getAuthToken = async () => client?.getTokenSilently()

export const getUser = async () => client?.getUser()

export const hasClinicalScope = async () => {
  const token = await getAuthToken()

  if (!token) {
    return false
  }

  const decodedToken = decode(token) as any

  return decodedToken.scope.includes('clinical')
}

export const handleRedirectCallback = async () =>
  client?.handleRedirectCallback()

export const getAuth0Client = () => client
