import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useDispatch, useSelector } from 'react-redux'
import { handleRedirectCallback } from '@babylon/auth0'
import { setLoggedIn, logout } from './actions'
import { getIsAuthenticated } from './selectors'
import Loading from '@/components/Loading'
import { displayFlashError } from '@/redux/flash/actions'

const useAuthenticationResult = () => {
  const { data, called, loading } = useQuery(
    gql`
      query registrationConfig {
        registrationConfig {
          fields {
            name
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const hasNextStep = data?.registrationConfig?.fields.length > 0

  return {
    shouldRedirect: called && !loading,
    hasNextStep,
  }
}

const urlParams = (url) =>
  url.search
    .substr(1)
    .split('&')
    .map((str) => str.split('='))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: val,
      }),
      {} as any
    )

// TODO: we get the registration config all the time, even when the user is not registering.

const AuthCallback = () => {
  const dispatch = useDispatch()
  const noParams = !window.location.search
  const isAuthenticated = useSelector(getIsAuthenticated)
  const { hasNextStep, shouldRedirect } = useAuthenticationResult()

  useEffect(() => {
    const handleRedirect = async () => {
      if (shouldRedirect) {
        const searchParams = urlParams(window.location)

        if (searchParams.error) {
          const errorMsg = `${searchParams.error}: ${decodeURIComponent(
            searchParams.error_description
          )}`
          console.error(errorMsg)
          dispatch(displayFlashError(errorMsg))
          setTimeout(() => {
            dispatch(logout())
          }, 4000)
        } else {
          const result = await handleRedirectCallback()

          const nextPage =
            result?.appState.registration && hasNextStep
              ? '/register'
              : result?.appState?.targetUrl
          dispatch(setLoggedIn(nextPage))
        }
      }
    }

    handleRedirect()
  }, [shouldRedirect]) // eslint-disable-line react-hooks/exhaustive-deps

  if (noParams || isAuthenticated) {
    return <Redirect to="/" />
  }

  return <Loading />
}

export default AuthCallback
