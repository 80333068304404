import { all, takeLatest } from 'redux-saga/effects'
import referFriend from './referFriend/sagas'
import { RESET_STORE } from './constants'
import { getClient } from '@/config/apollo-client'

function* resetApolloCache() {
  const client = getClient()
  yield client.resetStore()
}

function* resetStore() {
  yield takeLatest(RESET_STORE, resetApolloCache)
}

export default function* root() {
  yield all([resetStore(), referFriend()])
}
