import React from 'react'
import { useFormatMessage } from '@babylon/intl'

import { useBabylonBrandingEnabled } from '@/redux/selectors'
import Logo from '../../Logo'
import heartLogo from '@/assets/logo_heart_small.png'

import messages from './BabylonBranding.messages'
import styles from './BabylonBranding.module.scss'

const BabylonBranding = () => {
  const isBabylonBrandApp = useBabylonBrandingEnabled()
  const translate = useFormatMessage()

  return isBabylonBrandApp ? (
    <>
      <span className={styles.babylonBrandingCopy}>
        {translate(messages.madeWith)}{' '}
      </span>
      <img
        src={heartLogo}
        alt="Babylon heart icon"
        className={styles.heartIcon}
      />
    </>
  ) : (
    <>
      <span className={styles.babylonBrandingCopy}>
        {translate(messages.poweredBy)}{' '}
      </span>
      <Logo name="babylon" className={styles.babylonLogo} />
    </>
  )
}

export default BabylonBranding
