import { useSelector } from 'react-redux'

const useTrackingScriptsLoaded = () =>
  useSelector((state: any) => state.tracking.nonEssentialTrackingLoaded)

const selectAppName = (state: any) => state.appName
const selectEnvironment = (state: any) => state.env.BABYLON_ENVIRONMENT
const selectAppVersion = (state: any) => state.env.VERSION
const selectLocalisation = (state: any) => state.language || 'en-GB'
const selectUserProfile = (state: any) => state.auth.user?.id

export {
  useTrackingScriptsLoaded,
  selectAppName,
  selectEnvironment,
  selectLocalisation,
  selectUserProfile,
  selectAppVersion,
}
