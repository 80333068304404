import {
  trackEvent as gaTrackEvent,
  trackPageView as gaPageView,
} from '@babylon/tracking/gtag'
import { trackEvent as fbTrackEvent } from '@babylon/tracking/facebook'
import {
  trackEvent as tealiumTrackEvent,
  trackView as tealiumTrackView,
} from '@/tracking/tealium'

const urlParams = new URLSearchParams(window.location.search)
const isWebView = urlParams.get('isWebView') === 'true'

// There is the need to use different tracking platforms for Web and CyrusWebviews
// This is useful to avoid to add conditions for tracking in every place
// The tracking won't be triggered if the condition is not met
const trackGaEvent = (args) => (!isWebView ? gaTrackEvent(args) : false)
const trackGaPageView = (args) => (!isWebView ? gaPageView(args) : false)
const trackFbEvent = (args) => (!isWebView ? fbTrackEvent(args) : false)
const trackTealiumEvent = (args) =>
  isWebView ? tealiumTrackEvent(args) : false
const trackTealiumView = (args) => (isWebView ? tealiumTrackView(args) : false)

export {
  trackGaEvent,
  trackGaPageView,
  trackFbEvent,
  trackTealiumEvent,
  trackTealiumView,
}
