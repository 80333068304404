import gql from 'graphql-tag'
import axios from 'axios'
import uuid from 'uuid'
import { push } from 'connected-react-router'
import {
  login as loginRequest,
  register as registerRequest,
  logout as logoutRequest,
  getUser,
  getAuthToken,
} from '@babylon/auth0'
import { getClient } from '@/config/apollo-client'
import { displayFlashError } from '@/redux/flash/actions'

import { storeLastActiveTime } from './utils'
import {
  LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IDLE,
  UPDATE_ACTIVE_TIME,
} from './actionTypes'
import { resetStore } from '@/redux/actions'
import {
  selectAppName,
  selectDefaultLanguage,
  selectEnvironment,
} from '@/redux/selectors'
import { updateProductConfig } from '@/redux/productConfigReducer/actions'
import UserQuery from '@/queries/Patient'

const MutationValidateUser = gql`
  mutation validateUser($patientId: ID!) {
    validateUser(patientId: $patientId)
  }
`

const QueryCurrentUser = gql`
  query patient {
    patient {
      id
    }
  }
`

const loading = ({ isLoading }) => ({
  type: LOADING,
  isLoading,
})

const loginSuccess = ({ patient, authToken }) => (dispatch, getState) => {
  const client = getClient()
  const state = getState()

  const productConfigUrl = selectEnvironment(state)?.PRODUCT_CONFIG_URL

  if (productConfigUrl) {
    axios
      .get(productConfigUrl, {
        headers: {
          'X-App-Name': selectAppName(state),
          'Accept-Language': selectDefaultLanguage(state),
          'babylon-request-id': uuid(),
          'X-Platform': 'web',
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(({ data: productConfig }) =>
        dispatch(updateProductConfig(productConfig))
      )
  }

  client.mutate({
    mutation: MutationValidateUser,
    variables: { patientId: patient.id },
  })

  dispatch(loading({ isLoading: false }))

  return dispatch({
    type: LOGIN_SUCCESS,
    patient,
    lastActiveTime: Date.now(),
  })
}

const login = (email) => async () => loginRequest(email)

const register = () => async () => registerRequest()

const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

const logout = ({ redirectPath, message } = {}) => async (dispatch) => {
  // NOTE: order here is important; we first need to clear caches before redirecting

  dispatch(loading({ isLoading: true }))

  logoutRequest()

  dispatch(resetStore())

  dispatch(logoutSuccess())

  if (message) {
    dispatch(displayFlashError(message))
  }

  if (redirectPath) {
    // redirect
    window.location.replace(redirectPath)
  } else {
    dispatch(loading({ isLoading: false }))
  }
}

const setLoggedIn = (targetUrl) => async (dispatch) => {
  const auth0User = await getUser()
  const apolloClient = getClient()

  try {
    const { data } = await apolloClient.query({
      query: UserQuery,
      variables: { id: auth0User['https://babylonhealth.com/user'] },
    })

    const authToken = await getAuthToken()
    const { patient } = data

    dispatch(loginSuccess({ patient, authToken }))
  } catch {
    dispatch(logout())
    dispatch(loading({ isLoading: false }))
  } finally {
    if (targetUrl && targetUrl !== window.location.pathname) {
      dispatch(push(targetUrl))
    }
  }
}

const setLoggedInWebview = () => async (dispatch) => {
  const { webViewAuthToken } = window

  if (webViewAuthToken) {
    try {
      const client = getClient()

      const {
        data: { patient },
      } = await client.query({ query: QueryCurrentUser })

      return dispatch({
        type: LOGIN_SUCCESS,
        patient,
        lastActiveTime: Date.now(),
      })
    } finally {
      dispatch(loading({ isLoading: false }))
    }
  }

  return false
}

const lastActiveTimeAction = ({ lastActiveTime }) => ({
  type: UPDATE_ACTIVE_TIME,
  lastActiveTime,
})

const updateLastActiveTime = () => async (dispatch) => {
  const lastActiveTime = Date.now()

  storeLastActiveTime({ lastActiveTime })
  dispatch(lastActiveTimeAction({ lastActiveTime }))
}

const setIdle = ({ isIdle }) => ({
  type: SET_IDLE,
  isIdle,
})

export {
  loading,
  login,
  register,
  loginSuccess,
  logout,
  setLoggedIn,
  setLoggedInWebview,
  updateLastActiveTime,
  setIdle,
}
