export enum Tracker {
  Google = 'google',
  Facebook = 'facebook',
}

export interface MarketingTag {
  type: Tracker
  id: string
}

export interface TrackingEvent {
  match: RegExp
  callback: (pathname) => void
}
