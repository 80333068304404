import React, { useEffect, useState } from 'react'
import CookieNotice from './CookieNotice'

// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#option-2-lazily-show-component-with-uselayouteffect
// avoid rendering the cookie notice during server-side rendering

const Notice = () => {
  const [renderNotice, setRenderNotice] = useState(false)
  useEffect(() => {
    setRenderNotice(true)
  }, [])

  if (!renderNotice) {
    return null
  }

  return <CookieNotice />
}

export default Notice
