import React from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spinner } from '@babylon/medkit'

import { useLogo } from '@/redux/selectors'
import appMessages from '@/App.messages'
import logoHeart from '@/assets/logo_heart.png'
import styles from './Loading.module.scss'
import Logo from '../Logo'

const cx = classNames.bind(styles)

interface Props {
  className?: string
  inline?: boolean
  overlay?: boolean
  small?: boolean
  stretch?: boolean
  transparent?: boolean
  heart?: boolean
  title?: string
}

const Loading: React.FC<Props> = ({
  className,
  inline,
  overlay,
  small,
  stretch,
  transparent,
  title,
  heart,
}: Props) => {
  const t = useFormatMessage()
  const brandLogo = useLogo()

  return (
    <div
      className={cx(styles.loading, className, {
        'loading--inline': inline,
        'loading--overlay': overlay,
        'loading--small': small,
        'loading--stretch': stretch,
        'loading--transparent': transparent,
      })}
    >
      {heart ? (
        <Logo
          name={brandLogo}
          variant="square"
          fallback={logoHeart}
          data-testid="loading"
          className={styles.heart}
          alt={t(appMessages.loading)}
        />
      ) : (
        <Spinner variant="primary" />
      )}
      <span className={styles.loading__text} role="alert" aria-busy="true">
        {title || t(appMessages.loading)}
      </span>
    </div>
  )
}

export default Loading
