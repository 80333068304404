import { logException } from '@babylon/sentry'

export const loadScript = ({
  src,
  defer = true,
  async = true,
  attributes,
  onError,
}) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = async
    script.defer = defer
    if (attributes) {
      Object.keys(attributes).forEach((prop) =>
        script.setAttribute(prop, attributes[prop])
      )
    }

    script.onload = resolve
    script.onerror = reject
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded') {
        resolve(script)
      } else {
        logException(`Script not loaded: ${src}`)
        reject(onError)
      }
    }

    script.src = src
    document.body.appendChild(script)
  })

export const removeScript = (src) => {
  const scriptsArray = document.querySelectorAll(`script[src='${src}']`)
  scriptsArray.forEach((script) => document.body.removeChild(script))
}
