/*
 * Follow the steps described there : docs/standards/app-configuration/env-variable.md
 */

const {
  APP_API_ENDPOINT,
  BABYLON_ENVIRONMENT,
  BING_TRACKING_ID,
  CORE_URL,
  FACEBOOK_APP_ID,
  FACEBOOK_PIXEL_TOKEN,
  GOOGLE_API_KEY,
  // The google tracking ID is the GA/GTM key of UA-XXXXXXXX-X
  GOOGLE_TRACKING_ID,
  GOOGLE_ADS_TRACKING_ID,
  GRAPHQL_ENDPOINT,
  PLATFORM_GATEWAY_GRAPHQL_ENDPOINT,
  MICROSERVICES_URL,
  NHS_FACEBOOK_PIXEL_TOKEN,
  NODE_ENV,
  PRODUCT_CONFIG_URL,
  SENTRY_ENVIRONMENT,
  SENTRY_URL,
  // tracking
  TRACKING_DISABLED,
  ZENDESK_CHAT_API_KEY,
  // Marketing tags
  MARKETING_TAGS_ENABLED,
  SNAPCHAT_ID,
  // MentionMe RAF
  ENABLE_APPOINTMENT_TIMEZONE,
  AWIN_ID,
  AWIN_ENABLED,
  // Auth0
  AUTH0_DOMAIN_URL,
  AUTH0_CLIENT_ID,
} = window.babylonConfig || {}

const finalConfig = {
  API_KEY_STRING: 'api_key',
  APP_API_ENDPOINT:
    APP_API_ENDPOINT || 'https://app-uk.dev.babylontech.co.uk/api',
  AWIN_ID: AWIN_ID || 18257,
  AWIN_ENABLED: AWIN_ENABLED || false,
  BABYLON_ENVIRONMENT: BABYLON_ENVIRONMENT || 'dev',
  BING_TRACKING_ID: BING_TRACKING_ID || '',
  FACEBOOK_APP_ID: FACEBOOK_APP_ID || '417515351756917',
  FACEBOOK_PIXEL_TOKEN: FACEBOOK_PIXEL_TOKEN || '512024616307527',
  GOOGLE_API_KEY: GOOGLE_API_KEY || 'AIzaSyDO3jkWIoagfJ9LqKsBAA7iaNfRWxUx9jA',
  GOOGLE_TRACKING_ID: GOOGLE_TRACKING_ID || 'UA-78074544-3',
  GOOGLE_ADS_TRACKING_ID: GOOGLE_ADS_TRACKING_ID || '',
  GRAPHQL_ENDPOINT:
    GRAPHQL_ENDPOINT ||
    (NODE_ENV === 'production'
      ? '/graphql'
      : `https://localhost.${
          process.env.REACT_APP_ENV || 'dev'
        }.babylontech.co.uk:6443/graphql`),
  PLATFORM_GATEWAY_GRAPHQL_ENDPOINT:
    PLATFORM_GATEWAY_GRAPHQL_ENDPOINT ||
    `https://platform-uk.${process.env.REACT_APP_ENV}.babylontech.co.uk/graph`,
  NHS_FACEBOOK_PIXEL_TOKEN: NHS_FACEBOOK_PIXEL_TOKEN || '',
  PRODUCT_CONFIG_URL,
  SENTRY_ENVIRONMENT: SENTRY_ENVIRONMENT,
  SENTRY_URL: SENTRY_URL || '',
  VERSION: process.env.REACT_APP_VERSION,
  ZENDESK_CHAT_API_KEY:
    ZENDESK_CHAT_API_KEY || '1ab1bc9a-e52e-448a-a135-9c9b9a7883e8',

  // Auth0
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID || 'WvsqN8wVfTOKLt6jZhut2ytceU6H7l0w',
  AUTH0_DOMAIN_URL: AUTH0_DOMAIN_URL || 'auth.global1.dev.babylontech.co.uk',

  // These are chatbot items
  MICROSERVICES_URL:
    MICROSERVICES_URL || 'https://services-uk.dev.babylontech.co.uk',
  CORE_URL: CORE_URL || 'https://app-uk.dev.babylontech.co.uk',

  // tracking
  TRACKING_DISABLED: TRACKING_DISABLED || false,

  // Marketing tags
  MARKETING_TAGS_ENABLED,
  SNAPCHAT_ID: SNAPCHAT_ID || 'd6ec9328-3d08-46fb-8fc8-796de0ddc6c4',

  IS_SERVER: Boolean(window.isServer),
  ENABLE_APPOINTMENT_TIMEZONE,
}

module.exports = finalConfig
