import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getClient } from '@/config/apollo-client'
import userQuery from '@/queries/Patient'

const selectUserId = (state) => state.auth.user.id
const selectIsLoading = (state) => state.auth.user.isLoading
const selectIsIdle = (state) => state.auth.user.isIdle
const selectLoginStarted = (state) => state.auth.user.loginStarted
const selectLoginMethod = (state) => state.auth.user.loginMethod
const selectApolloRequestCount = (state) => state.apolloRequestCount

const getIsAuthenticated = createSelector([selectUserId], (userId) => !!userId)

const isLoading = createSelector([selectIsLoading], (isLoading) => isLoading)

const userId = createSelector([selectUserId], (userId) => userId)

const startedLoginMethod = createSelector(
  [selectLoginStarted, selectLoginMethod],
  (loginStarted, loginMethod) => loginStarted && loginMethod
)

const successfulLoginMethod = createSelector(
  [selectUserId, selectLoginMethod],
  (userId, loginMethod) => userId && loginMethod
)

const getUserData = createSelector(
  [selectUserId, selectApolloRequestCount],
  (userId, apolloRequestCount) => {
    const client = getClient()

    let userData

    if (userId && client && apolloRequestCount) {
      // as there are multiple queries on the page
      // readQuery throws an error when it's using the wrong query
      try {
        userData = client.readQuery({
          query: userQuery,
          variables: { id: userId },
        }).patient
      } catch {
        // we don't need to handle the error case
      }
    }

    return userData
  }
)

const useUserId = () => useSelector(selectUserId)

export {
  getIsAuthenticated,
  getUserData,
  isLoading,
  userId,
  selectUserId,
  selectIsIdle,
  startedLoginMethod,
  successfulLoginMethod,
  useUserId,
}
