import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import cn from 'classnames'
import loadable from '@loadable/component'
import { useFormatMessage } from '@babylon/intl'
import { ModalProvider, ScrollToTop } from '@babylon/medkit'
import PageTitle from '@/components/PageTitle/PageTitle'
import NoticePage from '@/views/NoticePage'
import Alert from './components/Alert'
import CookieNotice from './components/CookieNotice'
import AuthCallback from './components/Authentication/AuthCallback'
import messages from './App.messages'
import styles from './App.module.scss'
import 'normalize.css/normalize.css'

const GetStartedLoader = loadable(() =>
  import(/* webpackChunkName: "onboarding" */ './views/GetStartedPage')
)

const App = () => {
  const location = useLocation()
  const translate = useFormatMessage()

  return (
    <>
      <PageTitle />
      <ScrollToTop variableToWatch={location.pathname}>
        <ModalProvider
          closeButtonText={translate(messages.closeButtonText)}
          backButtonText={translate(messages.backButtonText)}
        >
          <div className={cn(styles.flexWrapper)}>
            <Alert />
            <CookieNotice />
            <Switch>
              <Route
                exact
                path="/privacy"
                component={() => <NoticePage type="privacyPolicy" />}
              />
              <Route
                exact
                path="/terms"
                component={() => <NoticePage type="termsAndConditions" />}
              />
              <Route path="/callback" exact render={() => <AuthCallback />} />
              <Route
                path="/"
                render={({ match }) => <GetStartedLoader {...match.params} />}
              />
            </Switch>
          </div>
        </ModalProvider>
      </ScrollToTop>
    </>
  )
}

export default App
