import {
  initialise as initialiseTealium,
  UTAG,
} from '@babylon/tracking/tealium'

const babylonEnvToTealiumEnv: { [key: string]: string } = {
  dev: 'dev',
  staging: 'dev',
  preprod: 'qa',
  prod: 'prod',
}

declare global {
  interface Window {
    utag: any
  }
}

const getScriptUrl = (env: string) =>
  `https://tags.tiqcdn.com/utag/babylon/uk/${env}/utag.js`

let initPromise: Promise<UTAG>
let tealiumInitialised = false

const injectScript = (src: string) =>
  new Promise<UTAG>((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.onload = () => {
      resolve(window.utag)
    }
    script.onerror = () => {
      reject()
    }
    script.src = src

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(script)
  })

const initialise = async (
  babylonEnv: string,
  regionIsoCode: string
): Promise<void> => {
  if (!initPromise) {
    const env = babylonEnvToTealiumEnv[babylonEnv]
    initPromise = injectScript(getScriptUrl(env))
  }

  try {
    const utag: UTAG = await initPromise
    await initialiseTealium(utag, regionIsoCode)
    tealiumInitialised = true
  } catch (e) {
    console.error('Cannot initialise Tealium')
  }
}

const isInitialised = () => tealiumInitialised

export default initialise
export { isInitialised }
