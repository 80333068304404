import { defineMessages } from 'react-intl'

export default defineMessages({
  dateFormat: {
    id: 'App.dateFormat',
    defaultMessage: 'DD/MM/YYYY',
  },
  email: {
    id: 'App.email',
    defaultMessage: 'Email',
  },
  readFaqs: {
    id: 'App.readFaqs',
    defaultMessage: 'Read our FAQs',
  },
  callUs: {
    id: 'App.callUs',
    defaultMessage: 'Call us',
  },
  contactSupport: {
    id: 'App.contactSupport',
    defaultMessage: 'Contact support',
  },
  supportPhoneNumber: {
    id: 'App.supportPhoneNumber',
    defaultMessage: '+443302231008',
  },
  supportEmail: {
    id: 'App.supportEmail',
    defaultMessage: 'support@babylonhealth.com',
  },
  brandName: {
    id: 'App.brandName',
    defaultMessage: 'GP at hand',
  },
  logo: {
    id: 'App.logo',
    defaultMessage: 'logo',
  },
  ctaHome: {
    id: 'App.ctaHome',
    defaultMessage: 'Go to homepage',
  },
  selected: {
    id: 'App.selected',
    defaultMessage: 'Selected',
  },
  done: {
    id: 'App.done',
    defaultMessage: 'Done',
  },
  thankYou: {
    id: 'App.thankYou',
    defaultMessage: 'Thank You',
  },
  tryAgain: {
    id: 'App.tryAgain',
    defaultMessage: 'Try again',
  },
  next: {
    id: 'App.next',
    defaultMessage: 'Next',
  },
  closeButtonText: {
    id: 'App.closeButtonText',
    defaultMessage: 'Close',
  },
  backButtonText: {
    id: 'App.backButtonText',
    defaultMessage: 'Back',
  },
  loading: {
    id: 'Loading.busy', // Moved from components/Loading. Keeping the id same to prevent re-translation.
    defaultMessage: 'Loading',
  },
})
