import { reduxState } from '@/redux'
import {
  selectEnvironment,
  selectUserProfile,
  selectAppVersion,
} from '@/tracking/selectors'

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    tealiumVisitorId: string
    userId: string
    userUuid: string
  }
}

export default () => {
  const { store } = reduxState
  const state = store.getState()

  // The varibles are set by the Cyrus App
  const {
    tealiumVisitorId,
    userId: webViewUserId,
    userUuid: webViewuserUuid,
  } = window

  const userId = selectUserProfile(state)?.id || webViewUserId
  const userUuid = selectUserProfile(state)?.uuid || webViewuserUuid
  const appEnvironment = selectEnvironment(state)
  const appVersion = selectAppVersion(state)

  return {
    app_build: appVersion,
    app_environment: appEnvironment,
    app_name: 'babylonUK',
    app_language: 'en-GB',
    country_iso_code: 'GBR',
    portal: 'monoweb',
    ...(userId ? { patient_id: userId } : {}),
    ...(userUuid ? { uuid: userUuid } : {}),
    ...(tealiumVisitorId && { tealium_visitor_id: tealiumVisitorId }),
  }
}
