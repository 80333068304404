/* eslint-disable import/group-exports */

import notificationTypes from '@babylon/notification-types'

export const gpAtHandApplicationStates = {
  PROCESSING: 'processing',
  ACTION_REQUIRED: 'action_required',
  UNQUEUED: 'unqueued',
}

export const idVerificationStates = {
  SUCCESS: 'succeeded',
  FAILURE: 'failed',
  PENDING: 'pending',
  UNKNOWN: 'unknown',
  NEW: 'new',
}

export const nhsDemographicStates = {
  PENDING: 'pending',
  FAILED: 'failed',
  RETRIEVED: 'retrieved',
  UNKNOWN: 'unknown',
}

// Type as received from braintree API
export const creditCardTypes = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro',
  DISCOVER: 'discover',
  AMEX: 'american express',
}

export const postcodeErrorKeys = [
  'address_first_line',
  'address_post_code',
  'address_second_line',
  'address_third_line',
  'errors',
]

export const excludedNotificationTypesFilters = [
  // part of healtcheck flow, which is not supported on the web app
  [{ field: 'item_type', value: notificationTypes.FEEDBACK_MODAL }],
  // part of healtcheck flow, which is not supported on the web app
  [{ field: 'item_type', value: notificationTypes.HEALTHCHECK_RATING }],
  // blood analysis is not supported on the web app
  [{ field: 'item_type', value: notificationTypes.BLOOD_ANALYSIS_HEALTHY }],
  // blood analysis is not supported on the web app
  [{ field: 'item_type', value: notificationTypes.BLOOD_ANALYSIS_APPOINTMENT }],
  // fired off when a doctor starts video call, cannot be used with polling every 10 seconds
  // instead we open a session and wait for doctor to connect
  [{ field: 'item_type', value: notificationTypes.APPOINTMENT_START }],
  // not meant to be shown, invisible notification used to make apps update user data
  [{ field: 'item_type', value: notificationTypes.DEMOGRAPHIC_STATUS_CHANGED }],
  // deprecated
  [{ field: 'item_type', value: notificationTypes.NEW_PRESCRIPTION_AVAILABLE }],
  // not relevant to user, not used for anything else
  [{ field: 'item_type', value: notificationTypes.APPOINTMENT_FINISHED }],
  // marketing for healthcheck, which is not supported on the web app
  [
    { field: 'item_type', value: notificationTypes.MARKETING },
    { field: 'additional_context.target', value: 'healthcheck' },
  ],
]

export const appStores = {
  GBR: {
    apple:
      'https://itunes.apple.com/gb/app/babylon-health-doctor-consultations/id858558101?mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.babylon&hl=en_GB', // It can't be changed to 'google' because of old tracking consistency
  },
  USA: {
    apple:
      'https://apps.apple.com/us/app/babylon-healthcheck-chatbot/id1455221792',
    android: 'https://play.google.com/store/apps/details?id=com.babylon.us',
  },
}

export const babylonWebsiteUrl = 'https://www.babylonhealth.com'
export const sevicesComparisonPageUrl =
  'https://www.babylonhealth.com/services-comparison-web'
export const gpAtHandWebsiteUrl = 'https://www.gpathand.nhs.uk'
export const gpAtHandClinicLocations = `${gpAtHandWebsiteUrl}/gp-clinics`

export const gpAtHandSupport = {
  email: 'gpathand@nhs.net',
  phone: '03308082217',
  helpCentre: 'https://support.gpathand.nhs.uk',
  supportTeam:
    'https://support.gpathand.nhs.uk/cspgpathand?id=csp_kb_article_view&sysparm_article=KB0010275',
  contactForm:
    'https://support.gpathand.nhs.uk/cspgpathand?id=csp_sc_cat_item&table=sc_cat_item&sys_id=d035aa93475f691067070b55d36d4303',
}

export const errors = {
  IMPORT_ERROR: 'IMPORT_ERROR',
}
export const logoutTimeout = 1000 * 60 * 30 // 30 minutes
export const stayLoggedInTimeout = 1000 * 60 // 1 minute

export const REGION_IDS = {
  UK: 1,
}

export const REGION_CODES = {
  UK: 'GBR',
  CAN: 'CAN',
  USA: 'USA',
}

export const UK_POSTCODE_REGEX = /[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\s?[0-9][a-zA-Z]{2}/g

export const KIOSK_MODE_COOKIE = 'kioskMode'
