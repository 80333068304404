import { initialise as initFbq } from '@babylon/tracking/facebook'

let scriptLoaded = false

const initFacebookAds = async (pixelId: string) => {
  if (!scriptLoaded) {
    const initScript = await import('./script')
    await initScript.default()
    scriptLoaded = true
  }

  if (typeof window.fbq === 'function') {
    window.fbq('init', pixelId)
    initFbq(window.fbq)
  }
}

export default initFacebookAds
