import React, { useState, useEffect } from 'react'
import { initialise as initAuthClient } from '@babylon/auth0'
import Loading from './components/Loading'
import { auth0 } from './constants'

declare global {
  interface Window {
    babylonConfig: any
  }
}

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Getting Auth0 config
    const { AUTH0_CLIENT_ID, AUTH0_DOMAIN_URL } = window.babylonConfig

    // Fallback for localhost development as babylonConfig doesn't get populated
    // Change the auth0.{ENV} values to reflect the environment if diferent than DEV
    const { clientId, domain } = auth0.DEV

    const config = {
      clientId: AUTH0_CLIENT_ID || (clientId as string),
      domain: AUTH0_DOMAIN_URL || (domain as string),
    }

    const initialise = async () => {
      await initAuthClient(config)

      setLoading(false)
    }
    initialise()
  }, [])

  return loading ? <Loading /> : children
}

export default AuthProvider
