import { defineMessages } from 'react-intl'

export default defineMessages({
  info: {
    id: 'CookieNotice.info',
    defaultMessage:
      'We use necessary cookies to make our site work. If you click to agree, we will also use optional analytics cookies. Learn more by visiting our <a>Cookie Policy</a>',
  },
  confirmText: {
    id: 'CookieNotice.confirm',
    defaultMessage: 'I agree',
  },
  declineText: {
    id: 'CookieNotice.decline',
    defaultMessage: 'No thanks',
  },
})
