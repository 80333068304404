import uuid from 'uuid/v4'
import loadScript from './script'
import { AWIN_ID, AWIN_ENABLED } from '@/config'

interface IConversion {
  amount: string // if a Conversion doesn't include payment then provide the string "1"
  channel: string // By default this is "aw"
  orderRef: string // A unique reference
  parts: string // concatenate the ConversionType enum and the amount e.g. `${ConversionType.default}:${price}`
  voucher: string //
  currency: string // should be populated with the ISO currency code of the currency that was used in the transaction. Example: GBP
}

enum ConversionType {
  registration = 'SIGNUPWEB',
  subscription = 'ANNSUB',
  payg = 'ONEOFF',
  appointmentBooked = 'BOOKAPPT',
  gpathandIdVerified = 'GPAHREG',
  default = 'Default',
}

const trackConversion = (
  type: ConversionType = ConversionType.default,
  amount: string = '1'
) => {
  const conversion: IConversion = {
    amount: amount,
    channel: 'aw',
    orderRef: uuid(),
    parts: `${type}:${amount}`,
    voucher: '',
    currency: 'GBP',
  }

  sendAWINEvent(conversion)
}

declare global {
  interface Window {
    AWIN: any
  }
}

window.AWIN = window.AWIN || {}

const sendAWINEvent = (conversion: IConversion) => {
  // if window.AWIN.Tracking is not defined don't track the event
  if (!window?.AWIN?.Tracking) {
    return false
  }

  const testMode = AWIN_ENABLED ? '0' : '1'
  const Sale = {
    ...conversion,
    orderRef: conversion.orderRef || uuid(),
    test: testMode,
  }

  // AWIN is included via loadScript
  // https://wiki.awin.com/index.php/Advertiser_Tracking_Guide#Conversion_Tag
  window.AWIN.Tracking.Sale = Sale
  window.AWIN.Tracking.run()

  // https://wiki.awin.com/index.php/Advertiser_Tracking_Guide/Standard_Implementation#Fall-back_Conversion_Pixel
  const pixel = document.createElement('img')
  pixel.setAttribute(
    'src',
    `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${AWIN_ID}&amount=${conversion.amount}&ch=${conversion.channel}&parts=${conversion.parts}&vc=${conversion.voucher}&cr=${conversion.currency}&ref=${conversion.orderRef}&testmode=${testMode}`
  )
  document.documentElement.appendChild(pixel)

  return true
}

export { trackConversion, ConversionType }
export default () => {
  loadScript()
}
