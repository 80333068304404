import { defineMessages } from 'react-intl'

export default defineMessages({
  madeWith: {
    id: 'Footer.BabylonBranding.madeWith',
    defaultMessage: 'Made with',
  },
  poweredBy: {
    id: 'Footer.BabylonBranding.poweredBy',
    defaultMessage: 'Powered by',
  },
})
