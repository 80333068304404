import { useSelector } from 'react-redux'
import {
  selectShowPromotionModal,
  selectPromocode,
} from '@/components/Authentication/Register/selectors'

interface BookingFlowProductConfig {
  enabled: boolean
  showFilters: boolean
}

interface InviteBookingFlowProductConfig {
  clinicianTypeEnabled: boolean
}

interface BookingFlowsProductConfig {
  serviceMix: BookingFlowProductConfig
  multiStep: BookingFlowProductConfig
  invite: InviteBookingFlowProductConfig
}

const useFacebookEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.facebookLogin)

const useAppointmentsCovidBannerEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.appointment?.booking?.coronavirusBannerEnabled
  )

const useBookAppointmentEnabled = () =>
  useSelector<any, boolean>(selectBookAppointmentsEnabled)

const useMultiStepBookingFlowEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.multiStep?.enabled ||
      state.config.bookingFlowNvlEnabled // TODO: remove this or statement once new config is live
  )

const useMultiStepBookingFlowFiltersEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.multiStep?.filtersEnabled
  )

const selectInviteBookingFlowConfig = (
  state
): undefined | BookingFlowsProductConfig['invite'] =>
  state.config.appointment?.booking?.bookingFlows?.invite

const useInviteBookingFlowConfig = () =>
  useSelector<any, undefined | BookingFlowsProductConfig['invite']>(
    selectInviteBookingFlowConfig
  )

const useMultiStepBookingFlowConfig = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) => state.config.appointment?.booking?.bookingFlows?.multiStep
  )

const useServiceMixBookingFlowConfig = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) => state.config.appointment?.booking?.bookingFlows?.serviceMix
  )

const useServiceMixBookingFlowEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.serviceMix?.enabled ||
      state.config.bookingFlowNvlEnabled // TODO: remove this or statement once new config is live
  )

const useServiceMixBookingFlowFiltersEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.serviceMix
        ?.filtersEnabled
  )

const useClientCompatibleFlowsCheckInEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.clientCompatibleFlowsCheckInEnabled
  )

const useClientCompatibleFlowsRtmEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.clientCompatibleFlowsRtmEnabled
  )

const useLocationCheckEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.locationCheckEnabled)

const useMultipleImagesBooking = () =>
  useSelector<any, number>(
    (state) => state.config.appointment?.booking?.multipleImages ?? -1
  )

const useVideoCallWithToken = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.videoAppointmentsWithToken
  )

const useNotificationsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.notifications)

const useCustomerSupportEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.customerSupport)

const useMarketingEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.marketing)

const useFeedbackEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.feedbackEnabled)

const useFeatureFlags = () =>
  useSelector<any, any>((state) => state.featureFlags)

const useDownloadAppEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.downloadApp)

const useReferAFriendEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.referAFriend)

const selectFamilyEnabled = (state: any) => !!state.config.family

const useFamilyEnabled = () => useSelector<any, boolean>(selectFamilyEnabled)

const useMembershipPlansEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.membershipPlans)

const usePaymentEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.payment)

const useChatbotEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.chatbot)

const useChatbotMicroFrontendEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.chatbotMicroFrontendEnabled
  )

const usePersonalDetailsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.personalDetailsOptions?.enabled
  )

const usePersonalDetailsAddressEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.personalDetailsOptions?.addressEnabled
  )

const useAllergiesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.allergies)

const useAppointmentsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.appointment?.enabled || state.config.appointments
  ) // TODO: remove this or statement once new config is live

const useGpDetailsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.gpDetails)

const useMedicalHistoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.medicalHistory)

const useMedicationsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.medications)

const usePharmaciesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.pharmacies)

const usePrescriptionsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.prescriptions)

const useTestAndKitsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.testAndKits)

const useChatHistoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.chatHistory)

const useBabylonBrandingEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.babylonBrandApp)

const useEnvironment = () => useSelector<any, any>(selectEnvironment)

const selectEnvironment = (state) => state.env

interface Auth0Config {
  clientId: string
  domain: string
}

const useAuth0Config = () =>
  useSelector<any, Auth0Config>((state) => state.config.auth0)

const useIdVerificationEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.idVerification)

const usePrivacyPolicyEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.privacyPolicy)

const useCookiesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.cookies)

const useTermsAndConditionsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.footer?.termsAndConditions
  )

const useRegulatoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.regulatory)

const usePartnerLogo = () =>
  useSelector<any, boolean>((state) => state.config.footer?.partnerLogo)

const useCookiePolicyLink = () =>
  useSelector<any, string>((state) => state.config.cookiePolicyLink)

const useRegulatoryPolicyLink = () =>
  useSelector<any, string>((state) => state.config.regulatoryPolicyLink)

const useCurrentUser = () => useSelector<any, any>((state) => state.auth.user)

const useCurrentUserId = () =>
  useSelector<any, any>((state) => state.auth.user?.id)

const useSupportPhoneNumber = () =>
  useSelector<any, any>((state) => state.config.supportPhoneNumber)

const useMissingProfileFields = () =>
  useSelector<any, any>((state) => state.config.askForMissingProfileFields)

const useAppTitle = () =>
  useSelector<any, string>((state) => state.config.appTitle) || 'Babylon'

const useAppName = () => useSelector<any, string>(selectAppName)

const selectAppName = (state) => state.config.appName || 'babylon'

const usePoweredByEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.poweredByEnabled)

const selectLogo = (state: any): string => state.config.logo

const useLogo = () => useSelector<any, string>(selectLogo)

type Registration = {
  phoneNumber: boolean
}

const useRegistrationFields = () =>
  useSelector<any, Registration>((state) => state.config.registration)

const useHealthcareIdentifierModalShown = () =>
  useSelector<any, boolean>((state) => !!state.healthcareIdentifierModalShown)

const useRegisterShowPromotionModal = () =>
  useSelector<any, boolean>(selectShowPromotionModal)
const useRegisterPromocode = () => useSelector<any, string>(selectPromocode)

const selectBookAppointmentsEnabled = (state: any) =>
  !!state.config.appointment?.booking?.enabled || state.config.bookAppointments // TODO: remove this or statement once new config is live

const selectLocalisation = (state) => state.config.localisation
const selectDefaultLanguage = (state) =>
  selectLocalisation(state).language || 'en-GB'

const useLocalisation = () => useSelector<any, any>(selectLocalisation)

const useDefaultRegion = () => useLocalisation().region || 'GBR'

const useDismissInvite = () =>
  useSelector<any, boolean>((state) => !!state.config.dismissInviteEnabled)

const useOnboarding = () =>
  useSelector<any, any>((state) => state.config.onboarding)

const useOnboardingRedirects = () =>
  useSelector<any, any>((state) => state.config.onboarding.redirects)

interface AppointmentProductConfig {
  recordingsLinkEnabled: boolean
  unavailableRecordingsTextEnabled: boolean
}

const useAppointmentConfig = () =>
  useSelector<any, undefined | AppointmentProductConfig>(
    (state) => state.config.appointment
  )

const useUnderMaintenance = () =>
  useSelector<any, boolean>((state) => state.config.underMaintenance)

const useHealthInformationEnabled = () =>
  useSelector<any, boolean>((state) => state.config.healthInformation)

const useHealthInformationLink = () =>
  useSelector<any, string>((state) => state.config.healthInformationLink)

const useWebSignupUrl = () =>
  useSelector<any, string>((state) => state.config.app.webSignupUrl)

const useWebAppUrl = () =>
  useSelector<any, string>((state) => state.config.app.webAppUrl)

const useGpahRegistrationRestricted = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.gpahRegistrationRestricted
  )

const useGpahRegistrationRestrictedCity = () =>
  useSelector<any, string>(
    (state) => state.config.gpahRegistrationRestrictedCity || ''
  )

const useGpahRegistrationRestrictedDistricts = () =>
  useSelector<any, string>(
    (state) => state.config.gpahRegistrationRestrictedDistricts || ''
  )

const useGpahRegistrationRestrictedPostcodePrefixes = () =>
  useSelector<any, string[]>((state) =>
    state.config.gpahRegistrationRestrictedPostcodePrefixes
      ? JSON.parse(state.config.gpahRegistrationRestrictedPostcodePrefixes)
      : []
  )

export {
  usePoweredByEnabled,
  useSupportPhoneNumber,
  useAppointmentsEnabled,
  useAppointmentsCovidBannerEnabled,
  useBookAppointmentEnabled,
  useClientCompatibleFlowsCheckInEnabled,
  useClientCompatibleFlowsRtmEnabled,
  useLocationCheckEnabled,
  useMultiStepBookingFlowConfig,
  useMultiStepBookingFlowEnabled,
  useMultiStepBookingFlowFiltersEnabled,
  useServiceMixBookingFlowConfig,
  useServiceMixBookingFlowEnabled,
  useServiceMixBookingFlowFiltersEnabled,
  useVideoCallWithToken,
  useBabylonBrandingEnabled,
  useEnvironment,
  selectEnvironment,
  useCustomerSupportEnabled,
  useDownloadAppEnabled,
  useReferAFriendEnabled,
  useFacebookEnabled,
  useFeedbackEnabled,
  useMarketingEnabled,
  useNotificationsEnabled,
  useFamilyEnabled,
  useMembershipPlansEnabled,
  usePaymentEnabled,
  usePersonalDetailsEnabled,
  useAllergiesEnabled,
  useGpDetailsEnabled,
  useMedicalHistoryEnabled,
  useMedicationsEnabled,
  usePharmaciesEnabled,
  usePrescriptionsEnabled,
  useTestAndKitsEnabled,
  useChatHistoryEnabled,
  useAuth0Config,
  useIdVerificationEnabled,
  usePrivacyPolicyEnabled,
  useTermsAndConditionsEnabled,
  useCookiesEnabled,
  usePartnerLogo,
  useCookiePolicyLink,
  useCurrentUser,
  useCurrentUserId,
  useAppTitle,
  useDefaultRegion,
  usePersonalDetailsAddressEnabled,
  useAppName,
  useLogo,
  useRegistrationFields,
  useHealthcareIdentifierModalShown,
  useRegisterShowPromotionModal,
  useRegisterPromocode,
  useLocalisation,
  useDismissInvite,
  useAppointmentConfig,
  useMultipleImagesBooking,
  selectBookAppointmentsEnabled,
  selectAppName,
  selectFamilyEnabled,
  useInviteBookingFlowConfig,
  selectInviteBookingFlowConfig,
  selectLocalisation,
  selectDefaultLanguage,
  selectLogo,
  useFeatureFlags,
  useRegulatoryEnabled,
  useRegulatoryPolicyLink,
  useChatbotEnabled,
  useChatbotMicroFrontendEnabled,
  useOnboarding,
  useMissingProfileFields,
  useUnderMaintenance,
  useHealthInformationEnabled,
  useHealthInformationLink,
  useWebSignupUrl,
  useWebAppUrl,
  useOnboardingRedirects,
  useGpahRegistrationRestricted,
  useGpahRegistrationRestrictedCity,
  useGpahRegistrationRestrictedDistricts,
  useGpahRegistrationRestrictedPostcodePrefixes,
}
