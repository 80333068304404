/**
 * standard event names of Facebook Pixel tracking
 */
export const standardEvents = <const>[
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'PageView',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
]

export type EventName = typeof standardEvents[number]

export type StandardEventProps =
  | facebook.Pixel.ViewContentParameters
  | facebook.Pixel.SearchParameters
  | facebook.Pixel.AddToCartParameters
  | facebook.Pixel.AddToWishlistParameters
  | facebook.Pixel.InitiateCheckoutParameters
  | facebook.Pixel.AddPaymentInfoParameters
  | facebook.Pixel.PurchaseParameters
  | facebook.Pixel.LeadParameters
  | facebook.Pixel.CompleteRegistrationParameters

type EventProp = boolean

interface EventProps {
  [prop: string]: EventProp
}

type StandardProps = { [event in EventName]: EventProps }

/**
 * standard properties of Facebook Pixel events
 *
 * boolean value defines if the property is required
 */
export const standardProps: StandardProps = {
  AddPaymentInfo: {
    content_category: false,
    content_ids: false,
    contents: false,
    currency: false,
    value: false,
  },
  AddToCart: {
    content_ids: false,
    content_name: false,
    content_type: false,
    contents: false,
    currency: false,
    value: false,
  },
  AddToWishlist: {
    content_name: false,
    content_category: false,
    content_ids: false,
    contents: false,
    currency: false,
    value: false,
  },
  CompleteRegistration: {
    content_name: false,
    currency: false,
    status: false,
    value: false,
  },
  Contact: {},
  CustomizeProduct: {},
  Donate: {},
  FindLocation: {},
  InitiateCheckout: {
    content_category: false,
    content_ids: false,
    contents: false,
    currency: false,
    num_items: false,
    value: false,
  },
  Lead: {
    content_category: false,
    content_name: false,
    currency: false,
    value: false,
  },
  PageView: {},
  Purchase: {
    content_ids: false,
    content_name: false,
    content_type: false,
    contents: false,
    currency: true,
    num_items: false,
    value: false,
  },
  Schedule: {},
  Search: {
    content_category: false,
    content_ids: false,
    contents: false,
    currency: false,
    search_string: false,
    value: false,
  },
  StartTrial: { currency: false, predicted_ltv: false, value: false },
  SubmitApplication: {},
  Subscribe: { currency: false, predicted_ltv: false, value: false },
  ViewContent: {
    content_ids: false,
    content_category: false,
    content_name: false,
    content_type: false,
    contents: false,
    currency: false,
    value: false,
  },
}
