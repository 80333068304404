import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from '@/components/Authentication/reducer'
import productConfigReducer from './productConfigReducer/reducer'
import featureFlags from './featureFlags/reducer'
import flashReducer from './flash/reducer'
import formErrorReducer from './formErrors/reducer'
import getStarted from '@/views/GetStartedPage/reducer'
import persistentFormReducer from './persistentForm/reducer'
import referFriendReducer from './referFriend/reducer'
import tracking from '@/tracking/reducer'
import {
  PRIVACY_AGREEMENT_NEEDED,
  UPDATE_APOLLO_REQUEST_COUNT,
} from './constants'
import config from '@/config'
import onboarding from '@/views/GetStartedPage/Templates/reducer'
import { membershipReducer as membership } from '@/membership'
import { getFlagObjectFromString } from '@/utils/featureFlags'

let reducers

const privacyAgreementNeeded = (state = false, action) => {
  if (action.type === PRIVACY_AGREEMENT_NEEDED) {
    return action.value
  }

  return state
}

const apolloRequestCount = (state = 1, action) => {
  if (action.type === UPDATE_APOLLO_REQUEST_COUNT) {
    return state + 1
  }

  return state
}

const getInitialState = (featureFlags) => ({
  env: config,
  config: window.initialState,
  ...(featureFlags && { featureFlags: getFlagObjectFromString(featureFlags) }),
})

const combine = (reducers) => {
  const initialState = getInitialState()
  const reducerNames = Object.keys(reducers)
  Object.keys(initialState).forEach((key) => {
    if (reducerNames.indexOf(key) === -1) {
      reducers[key] = (state = initialState[key]) => state
    }
  })

  return combineReducers(reducers)
}

const createReducers = (history) => {
  reducers = {
    apolloRequestCount,
    auth,
    config: productConfigReducer,
    featureFlags,
    flash: flashReducer,
    form: formErrorReducer,
    getStarted,
    persistentForm: persistentFormReducer,
    privacyAgreementNeeded,
    referFriend: referFriendReducer,
    tracking,
    router: connectRouter(history),
    onboarding,
    membership,
  }

  return combine(reducers)
}

const getReducers = () => reducers

export { getInitialState, getReducers }
export default createReducers
