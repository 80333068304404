import moment from 'moment'
import { snakeCase, find, get } from 'lodash'
import { logException } from '@babylon/sentry'

const snakeCaseKeys = (unformattedData) =>
  Object.keys(unformattedData).reduce((res, key) => {
    res[snakeCase(key)] = unformattedData[key]

    return res
  }, {})

const parseError = (error) => {
  let message

  try {
    message = JSON.parse((error || {}).message)
  } catch (e) {
    if (error) {
      error.message = error.message.replace(/{[^}]+}/, '[Filtered]') // Filter out query body from the error
      logException(error)
    } else {
      logException(e)
    }

    return { unparsedError: true } // Trigger a flash message for an unknown error
  }

  return message
}

// get the parsedError object from a graphQL error, if it exists
const getParsedErrorObject = (error) =>
  get(error, 'graphQLErrors[1].parsedError', {})

const getUserErrorMessage = (error) => getParsedErrorObject(error).user_message

const getInitials = (name = '', surname = '') =>
  `${name.charAt(0)}${surname.charAt(0)}`

const isAdult = (dateOfBirth, ageOfConsent) =>
  moment(dateOfBirth).isBefore(moment().subtract(ageOfConsent, 'years'))

const isDesktop = () => window.matchMedia('(min-width: 769px)').matches

const promisify = (fn, context = this) => (...args) =>
  new Promise((resolve, reject) => {
    fn.apply(context, [
      ...args,
      (err, result) => {
        if (err) {
          return reject(err)
        }

        resolve(result)

        return undefined
      },
    ])
  })

const checkAuthErrors = (props = {}, apolloPropNames = []) => {
  apolloPropNames.forEach((name) => {
    const apolloError = (props[name] || {}).error

    if (
      apolloError &&
      apolloError.graphQLErrors &&
      apolloError.graphQLErrors.length
    ) {
      const parsedError = parseError(apolloError.graphQLErrors[0])

      if ((parsedError.response || {}).status === 403 && props.history) {
        props.history.replace('/')
      }
    }
  })
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 */
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'unknown'
}

const isAndroid = () => getMobileOperatingSystem() === 'android'

const isIos = () => getMobileOperatingSystem() === 'ios'

/* TO DO: [Refactor] [CW-1345] Reduce complexity to align with new linting rules */
/* eslint-disable complexity */
const formatPriceWithCurrency = (price, currency, isDecimalFixed) => {
  const { thousands_separator, symbol, symbol_first, decimal_mark } = currency

  if (!Number.isNaN(parseFloat(price))) {
    const split = price.toString().split('.')

    let integer = split[0]
    const fractional = split[1]
    const parsedFractional =
      isDecimalFixed && fractional && fractional.length === 1
        ? `${fractional}0`
        : fractional
    const formattedFractional = fractional
      ? `${decimal_mark}${parsedFractional}`
      : ''

    const thousands = Math.floor(integer / 1000)
    const formattedThousands = thousands
      ? `${thousands}${thousands_separator}`
      : ''

    integer -= 1000 * thousands

    if (thousands > 0) {
      while (integer.toString().length < 3) {
        integer = `0${integer}`
      }
    }

    let formattedPrice = `${formattedThousands}${integer}${formattedFractional}`

    if (symbol_first) {
      formattedPrice = `${symbol} ${formattedPrice}`
    } else {
      formattedPrice = `${formattedPrice} ${symbol}`
    }

    return formattedPrice
  }

  return price
}
/* eslint-enable */

// Parse a string to match slug requirements
const slugify = (string) =>
  string
    .toString()
    .toLowerCase()
    .replace(/[\s-]+/g, '-') // Replace all spaces and hyphens (multiple) with hyphens
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/^-+|-+$/g, '') // Remove leading and closing hyphens

const hasHttpProtocol = (value) => /^(http)s?:\/\//.test(value)

const getLocale = () => {
  const lang = navigator.userLanguage || navigator.language || 'en' // userLanguage is IE10

  return lang.toLowerCase().split(/[_-]+/)[0]
}

const isFilledArray = (array) => Array.isArray(array) && array.length > 0

const getRegionIsoCodeById = (regions, id) =>
  find(regions, { value: id }).iso_code

const deleteKeysInObject = (object, keys) =>
  Object.keys(object).reduce((acc, key) => {
    if (keys.includes(key)) {
      return acc
    }

    acc[key] = object[key]

    return acc
  }, {})

export {
  snakeCaseKeys,
  parseError,
  getParsedErrorObject,
  getUserErrorMessage,
  getInitials,
  isAdult,
  isDesktop,
  promisify,
  checkAuthErrors,
  getMobileOperatingSystem,
  isAndroid,
  isIos,
  formatPriceWithCurrency,
  slugify,
  hasHttpProtocol,
  getLocale,
  isFilledArray,
  getRegionIsoCodeById,
  deleteKeysInObject,
}
