// It is needed to help localhost development
const auth0 = {
  DEV: {
    clientId: 'WvsqN8wVfTOKLt6jZhut2ytceU6H7l0w',
    domain: 'auth.global1.dev.babylontech.co.uk',
  },
  STAGING: {
    clientId: 'l6USbx8XRG7uxo26cDYqMEVDtwNZWNNl',
    domain: 'auth.global1.staging.babylontech.co.uk',
  },
  PREPROD: {
    clientId: 'JVSctPoInv8O6AsyKHHhRhpuK9UFj3Bz',
    domain: 'auth.global1.preprod.babylontech.co.uk',
  },
}

export { auth0 }
