import React from 'react'

import PartnerBranding from './PartnerBranding'
import BabylonBranding from './BabylonBranding'
import { usePartnerLogo } from '@/redux/selectors'
import styles from './Footer.module.scss'

const LandingFooter = () => {
  const partnerLogo = usePartnerLogo()

  return (
    <footer className={styles.footer}>
      {' '}
      {partnerLogo && <PartnerBranding logo={partnerLogo} />}
      <BabylonBranding />
    </footer>
  )
}

export default LandingFooter
