import { logException } from '@babylon/sentry'
import { Messages } from '@babylon/intl'

const getMessagesOnLocaleChange = async (locale: string): Promise<Messages> => {
  try {
    const [messagesData] = await Promise.all<Messages>([
      import(
        /* webpackChunkName: "getstarted-messages.[request]" */
        `../messages/${locale.replace('-', '_')}.json`
      ),
    ])

    if (messagesData) {
      return messagesData
    }
  } catch (error) {
    logException(error)
  }

  return {} as Messages
}

export default getMessagesOnLocaleChange
