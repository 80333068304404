/* eslint-disable import/prefer-default-export */
/**
 * Implements Facebook Pixel tracking via `fbevents.js`
 *
 * https://developers.facebook.com/docs/facebook-pixel/
 *
 * Exported methods are wrapped to check if the library has been
 * initialised and will throw otherwise.
 *
 * ```ts
 * import initialise from '@babylon/tracking/facebook'
 *
 * initialise(window.fbq)
 * trackEvent('AddToCart', { content_name: 'GP at Hand' })
 * ```
 */

import { EventName, StandardEventProps } from './fbq'
import createEventTracker, { EventTracker } from './trackEvent'

let initialised = false
let eventTracker: EventTracker

const initialise = (fbq: facebook.Pixel.Event) => {
  if (typeof fbq !== 'function') {
    throw new Error(
      '@babylon/tracking/facebook has not been initialised because "fbq" was not a function'
    )
  }

  if (!initialised) {
    eventTracker = createEventTracker(fbq)
    initialised = true
  } else {
    // eslint-disable-next-line no-console
    console.log('@babylon/tracking/facebook has already been initialised')
  }
}

/**
 * Events can be triggered before facebook tracking as been initialised, e.g. on
 * page load.
 *
 * We keep them in a queue so we can send them later
 */
interface QueuedEvent {
  event: EventName
  props?: StandardEventProps
}

const queue: QueuedEvent[] = []

const trackEvent: EventTracker = (
  event: EventName,
  props?: StandardEventProps
) => {
  queue.push({ event, props })

  while (initialised && queue.length) {
    const queuedEvent = queue.shift()
    if (queuedEvent) {
      eventTracker(queuedEvent.event, queuedEvent.props)
    }
  }
}

export { initialise, trackEvent }
